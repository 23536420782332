.main-logo{
  width: 250px;
}
*:focus {
  box-shadow: none !important;
}
.heroSection .row{
  min-height: 700px;
  padding: 100px 0;
}
.hero{
  position: relative;
  overflow: hidden;
}
.heroSection h1{
  font-size: 70px;
  font-weight: 800;
}
.container{
  overflow: hidden;
}
#background-video {
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
}
.bg-scroll .bg-body-tertiary {
  padding: 0;
  --bs-bg-opacity: 1 !important;
  transition: all .2s ease-in-out;
  border-bottom: 2px solid #E02B20;
}
.bg-body-tertiary{
  --bs-bg-opacity: .35 !important;
  transition: all .2s ease-in-out;
  background-color: rgba(255,255,255, .5) !important;
}
.bg-scroll  .text-white.nav-link{
  color: #000 !important;
}
.bg-scroll .text-white.nav-link.item--active {
  color: #e02b20 !important;
  /* background: #fff !important; */
}
.offcanvas.offcanvas-end.show .text-white.nav-link.item--active{
  color: #e02b20 !important;
}
.text-white.nav-link:hover {
  color: #e02b20 !important;
  /* background: #fff !important; */
}
.cs-shadow{
  text-shadow: 0.03em 0.03em 0.05em rgba(0,0,0,0.4);
}
.typetext{
  font-size: 30px;
}
.subHeading{
  text-transform: uppercase;
  font-size: 20px;
  color: #FFFFFF!important;
  letter-spacing: 8px;
}
.btn-cs{
  margin-top: 20px;
  padding: 15px 25px !important;
  color: #ffffff!important;
  border-width: 0px!important;
  font-weight: 600!important;
  text-transform: uppercase!important;
  background-color: #E02B20 !important;
  border-radius: 0 !important;
}
.red{
  color: #e02b20 !important;
}
.offers{
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 20px;
}
.offers span{
  font-weight: bold;
}
.about,
.services,
.why,
.dataDriven{
  padding: 100px 0;
}
.services{
  background: #f7f7f7 !important;
}
p.card-text {
  min-height: 120px;
}
.card{
  border-radius: 0 !important;
  box-shadow: 0 0 13px 0px #b1b1b1;
}
.card-img-extra-paddng {
  padding: 15px;
}
.ser{
  margin-bottom: 50px !important;
}
.red.card-title.h5 {
  font-weight: bold;
}
.btn-cs-2 {
  padding: 15px 25px !important;
  color: #E02B20!important;
  border-width: 0px!important;
  font-weight: 600!important;
  text-transform: uppercase!important;
  background-color: #fff !important;
  border-radius: 0 !important;
}
.cta{
  position: relative;
  padding: 50px 0;
  background: rgba(38, 42, 52, .98);
  overflow: hidden;
}
.cta:after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-top: 50px solid #E02B20;
  border-left: 100vw solid transparent;
  /* z-index: -1; */
}
.cta .container{
  position: relative;
  z-index: 1;
}
.cta-text{
  font-size: 31px;
  color: #FFFFFF!important;
  text-align: left;
}
.post-grid .card-img-extra-paddng{
  text-align: center;
  color: #E02B20;
}
.post-grid .card-img-extra-paddng svg{
  font-size: 50px;
}
.post-grid .card{
  padding: 25px;
}

.dataDriven .container{
  position: relative;
  z-index: 1;
}
.dataDriven{
  position: relative;
  padding: 100px 0;
  background: #dbdbdb;
  overflow: hidden;
}
.dataDriven:after {
  content: "";
  position: absolute;
  top: 0;
  width: 10%;
  height: 100%;
  border-top: 70vw solid #fff;
  border-right: 30vw solid transparent;
  /* z-index: -1; */
}
.dataDriven .first-col h2{
  margin-bottom: 20px;
  font-size: 50px;
}
.accordion-item{
  border-radius: 0 !important;
  margin: 20px 0 !important;
  /* background: #E02B20 !important; */
}
.accordion-item button{
  background: #E02B20 !important;
  color: #fff !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  font-size: 20px;
}

.accordion-item button[aria-expanded=true]{
  background: #ffffff !important;
  color: #000 !important;
  pointer-events: none;
}
.accordion-item button[aria-expanded=true]:after{
  background: unset;
}

.first-col{
  padding: 0 50px 0 0 !important;
}
.solo-img {
  padding: 10px;
  background: #fff;
}
.slider-section{
  margin-top: 30px !important;
}
.slider-area {
  padding: 0 10px;
} 

footer .f-col{
  padding: 100px 0;
  background: #212121;
  border-top-width: 10px;
  border-top-color: #e02b20;
}
footer .list-group-item{
  padding: 0;
  background: transparent !important;
  border: 0;
}
footer .list-group-item svg{
  font-size: 40px;
  margin-right: 10px;
}
.footer-desc{
  font-size: 20px;
}

.copyright{
  padding: 30px 0;
  background: #0a0a0a;
  color: #fff;
  text-align: center;
}
.copyright p{
  margin-bottom: 0 !important;
}
.navbar-toggler.collapsed{
  border-color: #fff !important;
  outline: none;
}
.navbar-toggler.collapsed:active{
  outline: none;
}
.navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}
.bg-scroll .navbar-toggler.collapsed{
  border-color: #e02b20 !important;
}

.bg-scroll .navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(224,43,32, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.offcanvas.show .offcanvas-body .nav-link{
  color: #000 !important;
}
.nav-link{
  text-transform: uppercase;
}
.accordion-button::after{
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.scroll-to-top{
  text-align: center;
}
#about,
#services,
#why,
#contact{
  scroll-margin-top: 80px;
}
.form-static input,
.form-static textarea,
.form-static select{
  background: transparent !important;
  border-bottom: 1px solid #fff !important;
  border: 0;
  border-radius: 0 !important;
  color: #fff !important;
  outline: none !important;
  padding: 20px 5px !important;
}
.form-static input::placeholder,
.form-static textarea::placeholder,
.form-static select::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

.form-static input:-ms-input-placeholder,
.form-static textarea:-ms-input-placeholder,
.form-static select:-ms-input-placeholder{ /* Internet Explorer 10-11 */
  color: #FFF;
}

.form-static input::-ms-input-placeholder,
.form-static textarea::-ms-input-placeholder,
.form-static select::-ms-input-placeholder{ /* Internet Explorer 10-11 */
  color: #FFF;
}
.form-static form{
  background: #2a2e37;
  padding: 20px;
}
.cs-btn-form{
  border-radius: 0 !important;
  background: #e02b20 !important;
  outline: none;
  border: 0 !important;
  min-width: 200px;
  padding: 15px 20px !important;
}

@media only screen and (max-width: 1130px) {
  #background-video {
    width: 150%;
  }
}

@media only screen and (max-width: 1023px) {
  .heroSection .row {
    min-height: 550px;
    padding: 50px 0;
  }
  .about, .services, .why, .dataDriven {
    padding: 50px 0;
  }
  .offers {
    font-size: 30px;
  }
  .cta-text {
    font-size: 16px;
  }
  .dataDriven .first-col h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .main-logo {
    width: 180px;
  }
  #about,
  #services,
  #why,
  #contact{
    scroll-margin-top: 66px;
  }
}

@media only screen and (max-width: 767px) {
  #about,
  #services,
  #why,
  #contact{
    scroll-margin-top: 55px;
  }
  .copyright p {
    font-size: 14px;
  }
  .footer-desc {
    font-size: 14px;
  }
  footer .f-col {
    padding: 20px 0;
  }
  .f-col .text-white.list-group-item{
    display: flex;
    font-size: 14px;  
  }
  .float-end.list-group.list-group-horizontal {
    float: unset !important;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
  #background-video {
    width: 220%;
  }
  .dataDriven .first-col h2 {
    font-size: 20px;
  }
  .accordion-section{
    padding: 0 !important;
  }
  .first-col {
    padding: 0 15px !important;
  }
  .cta-text {
    font-size: 14px;
    text-align: center;
  }
  #about p{
    font-size: 14px;
  }
  .post-grid .card {
    padding: 0;
  }
  p.card-text {
    font-size: 14px;
  }
  .red.card-title.h5 {
    font-size: 16px;
  }
  .ser {
    margin-bottom: 10px !important;
  }
  .about, .services, .why, .dataDriven {
    padding: 20px 0;
  }
  .offers {
    font-size: 20px;
  }
  .main-logo {
    width: 150px;
  }
  .heroSection h1 {
    font-size: 29px;
  }
  .typetext {
    font-size: 16px;
  }
  .heroSection .row {
    min-height: 350px;
    padding: 100px 0 20px;
  }
}
